import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-stockin-modal',
  templateUrl: './stockin-modal.page.html',
  styleUrls: ['./stockin-modal.page.scss'],
})
export class StockinModalPage implements OnInit {
date;
invoice;
litres;
stockin: FormGroup;
  constructor(private modalCltr: ModalController) { }

  ngOnInit() {

  }
async close(){
  console.log(this.date+""+this.invoice+""+this.litres);
  this.modalCltr.dismiss({
    date:this.date,
    invoice:this.invoice,
    litres:this.litres
  })
}


}
