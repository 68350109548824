import { ID } from '@datorama/akita';

export interface Transaction {
  amount: number,
    authorisationCode:string
    balance: number,
    currency:string
    datestring
    description: string,
    externalId: string,
    externalUniqueId: string,
    fee: number,
    location: string,
    otherWalletId: number,
    payment: [{
      acceptedCardSchemes: [
        string
      ],
      acceptedPaymentMechanisms: [
        string
      ],
      additionalFields: [
        {
          defaultValue: string,
          fixed: string,
          id: string,
          regex: string,
          regexErrorMessage: string,
          title: string,
          type: number,
          value: string
        }
      ],
      amount: number,
      amountOption: string,
      authCode: string,
      bankResponse: string,
      completionUrl: string,
      created:string,
      currency: string,
      customerId: number,
      description: string,
      errorDescription: string,
      externalUniqueId: string,
      extraInfo: string,
      fee: number,
      lastModified: string,
      location: string,
      merchantId: string,
      merchantName: string,
      note: string,
      organisationId: number,
      partialPaymentAllowed: true,
      paymentData: string,
      paymentId: number,
      paymentInstrumentInfo: [{
        cardBin: string,
        cardLast4: string,
        cardName: string,
        cardOnFileId: string,
        cardType: string,
        externalWalletId: string,
        externalWalletType: string
      }],
      paymentReference: string,
      paymentType: string,
      retrievalReferenceNumber: number,
      status: string,
      token: string,
      walletId: number
    }],
    transactionId: string,
    type: string,
    walletId: number,
    withdrawal: [{
      amount: number,
      created: string,
      currency: string,
      deliverToPhone: string,
      description: string,
      errorDescription: string,
      expires: string,
      externalUniqueId: string,
      extraInfo: string,
      fee: number,
      finalAmount: number,
      gateway: string,
      reference: string,
      status: string,
      subType: string,
      token: string,
      type: string,
      walletId: number,
      withdrawalId: number
}]
}
export function createTransaction(params: Partial<Transaction>) {
  return {

  } as Transaction;
}
