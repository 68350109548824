import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Organization, OrganizationQuery } from '.';
import { EclipseQuery, EclipseService } from '../eclipse';
import { OrganizationStore } from './organization.store';
import { v4 as uuidv4 } from 'uuid';

const eclipseBaseUrl = environment.eclipseBaseURL;
const tenantId = environment.tenantId;
const walletTypeId = environment.zfWalletType;


@Injectable({ providedIn: 'root' })
export class OrganizationService {

  
  constructor(
    private organizationStore: OrganizationStore, 
    private organizationQuery: OrganizationQuery,
    private eclipseService: EclipseService,
    private eclipseQuery: EclipseQuery,
    private http: HttpClient) {
  }

  

  async get() {
    
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.eclipseQuery.getValue().data.headerValue
        
      })
    };
    //return await this.http.get(`${eclipseBaseUrl}/tenants/${tenantId}/organisations?limit=25&offset=0`,httpOptions
    console.log("before");
    return await this.http.get<[]>(`${eclipseBaseUrl}/tenants/${tenantId}/organisations?limit=25&offset=0`,httpOptions)
      .toPromise().then(result =>{
         const entities= result;
      //  console.log(entities);
      this.organizationStore.set(entities);
      

      }).catch(async error=>{
        console.log(error.error[0].description);
        if(error.error[0].description.includes("JWT has expired")){
          console.log("Renewing JWT token");
          await this.eclipseService.renewToken();
          setTimeout(async ()=>{
           this.get();
            },1000);

      }
    })
      

      
      
    
  }

  async register(details:any){
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.eclipseQuery.getValue().data.headerValue
        
      })
    };
    const result = this.http.post(
      `${eclipseBaseUrl}/tenants/${tenantId}/organisations`,
      details,
      httpOptions,
    );

    console.log(result);
    return result;

  }

  async docUpload(docType: string, organisationId: string, base64Doc: string){
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.eclipseQuery.getValue().data.headerValue
      })
    };

    return this.http.post(
      `${eclipseBaseUrl}/tenants/${tenantId}/organisations/${organisationId}/documents`,
      {
        "base64EncodedDocument": base64Doc,
        "documentType": docType,
        "mediaType": "application/pdf"
      },httpOptions,
    );
  }



  async ratify(organisationId: string){
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.eclipseQuery.getValue().data.headerValue
      })
    };

    return this.http.post(
      `${eclipseBaseUrl}/tenants/${tenantId}/organisations/${organisationId}/ratify`,
      {
      }, httpOptions,
    );
  }

  async createWallet(organisationId: string){
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.eclipseQuery.getValue().data.headerValue
      })
    };

    return this.http.post(
      `${eclipseBaseUrl}/tenants/${tenantId}/organisations/${organisationId}/wallets`,
      {
       
        "description": "zire fuel organisation wallet",
        "externalUniqueId": uuidv4(),
        "name": "Fuel Wallet",
        "status": "ACTIVE",
        "walletTypeId": walletTypeId,
      }, httpOptions,
    );
  }


}

