import { ID } from '@datorama/akita';

export interface Organization {
        organisationId: ID;
        name: string,
        email: string,
        phone1: string,
        companyNumber: string,
        taxNumber:string,
        created: string,
        lastModified: string,
        tradingName: string,
        industrialSector: string,
        industrialClassification: string,
        version: number,
        externalUniqueId: string,
        bankDetails: [
            {
                att: string,
                val: string
            }
           
        ]
  
}

export function createOrganization(params: Partial<Organization>) {
  return {

  } as Organization;
}
