import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { TransactionStore, TransactionState } from './transaction.store';

@Injectable({ providedIn: 'root' })
export class TransactionQuery extends QueryEntity<TransactionState> {

  constructor(protected store: TransactionStore) {
    super(store);
  }

}
