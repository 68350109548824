import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CustomerQuery } from '.';
import { EclipseQuery, EclipseService } from '../eclipse';
import { CustomerStore, CustomerState } from './customer.store';

const eclipseBaseUrl = environment.eclipseBaseURL;
const tenantId = environment.tenantId;

@Injectable({ providedIn: 'root' })
export class CustomerService {
customerId:string;

nationalId: string;
thumbsUpSelfie: string;
  
  constructor(
    private customerStore: CustomerStore,
    private customerQuery: CustomerQuery,
    private eclipseService: EclipseService,
    private eclipseQuery: EclipseQuery,
    private http: HttpClient
  ) {
    
  }

  async get(){
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.eclipseQuery.getValue().data.headerValue
        
      })
    };
    return await this.http.get<[]>(`${eclipseBaseUrl}/tenants/${tenantId}/customers`,httpOptions)
      .toPromise().then(result =>{
         const entities= result;
      //  console.log(entities);
      this.customerStore.set(entities);
      

      }).catch(async error=>{
        console.log(error.error[0].description);
        if(error.error[0].description.includes("JWT has expired")){
          console.log("Renewing JWT token");
          await this.eclipseService.renewToken();
          setTimeout(async ()=>{
           this.get();
            },1000);
         

        }
      })
      



  }

}
