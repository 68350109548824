import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Organization } from './organization.model';

export interface OrganizationState extends EntityState<Organization, number> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'organization',
  idKey:'organisationId'
})

export class OrganizationStore extends EntityStore<OrganizationState> {

  constructor() {
    super({ filter: 'ALL'});

  }

}
