import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { WalletQuery } from '.';
import { EclipseQuery, EclipseService } from '../eclipse';
import { WalletStore } from './wallet.store';
import { environment } from 'src/environments/environment';

const eclipseBaseUrl = environment.eclipseBaseURL;
const tenantId = environment.tenantId;
@Injectable({ providedIn: 'root' })
export class WalletService {

  constructor(private walletStore: WalletStore, private walletQuery:WalletQuery,  private eclipseService: EclipseService,
    private eclipseQuery: EclipseQuery,
    private http: HttpClient) {
  }

  async get(Id,type) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.eclipseQuery.getValue().data.headerValue

      })
    };
    return await this.http.get<[]>(`${eclipseBaseUrl}/tenants/${tenantId}/${type}/${Id}/wallets`,httpOptions)
      .toPromise().then(result =>{
         const entities= result;
      //  console.log(entities);
      this.walletStore.set(entities);


      }).catch(async error=>{
        console.log(error.error[0].description);
        if(error.error[0].description.includes("JWT has expired")){
          console.log("Renewing JWT token");
          await this.eclipseService.renewToken();
          setTimeout(async ()=>{
           this.get(Id,type);
            },1000);

       } })

  }

  async createWallet(custId){ //create wallet
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.eclipseQuery.getValue().data.headerValue

      })
    };
    return await this.http.post(`${eclipseBaseUrl}/tenants/${tenantId}/customers/${custId}/wallets`,httpOptions);
  }

  async get2(wallID){
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.eclipseQuery.getValue().data.headerValue

      })
    };
    return await this.http.get<[]>(`${eclipseBaseUrl}/tenants/${tenantId}/wallets/${wallID}`,httpOptions)
      .toPromise().then(result =>{
         const entities= result;
      //  console.log(entities);
      this.walletStore.set(entities);


      }).catch(async error=>{
        console.log(error.error[0].description);
        if(error.error[0].description.includes("JWT has expired")){
          console.log("Renewing JWT token");
          await this.eclipseService.renewToken();
          setTimeout(async ()=>{
           this.get2(wallID);
            },1000);

       } });
  }

}
