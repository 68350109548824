import { ID } from '@datorama/akita';

export interface Wallet {
  availableBalance: number,
    created: string,
    currency: string,
    currentBalance: number,
    customerId: number,
    description: string,
    externalUniqueId:string,
    friendlyId: string,
    name: string,
    organisationId: number,
    reservations: number,
    status: string,
    walletId: ID,
    walletTypeId: number
}

export function createWallet(params: Partial<Wallet>) {
  return {

  } as Wallet;
}
