import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Wallet } from './wallet.model';

export interface WalletState extends EntityState<Wallet,number> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'Wallet',
  idKey:'walletId'
})
export class WalletStore extends EntityStore<WalletState> {

  constructor() {
    super({ filter: 'ALL'});
  }

}
