import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TransactionQuery } from '.';
import { EclipseQuery, EclipseService } from '../eclipse';
import { TransactionStore } from './transaction.store';

const eclipseBaseUrl = environment.eclipseBaseURL;
const tenantId = environment.tenantId;

@Injectable({ providedIn: 'root' })
export class TransactionService {

  constructor(private transactionStore: TransactionStore,
    private transactionQuery: TransactionQuery,  
    private eclipseService: EclipseService,
    private eclipseQuery: EclipseQuery,
    private http: HttpClient) {
  }

  async get(wallId) {

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.eclipseQuery.getValue().data.headerValue
        
      })
    };

   return await this.http.get<[]>(`${eclipseBaseUrl}/tenants/${tenantId}/wallets/${wallId}/transactions`,httpOptions)
      .toPromise().then(result =>{
         const entities= result;
      //  console.log(entities);
      this.transactionStore.set(entities);
      

      }).catch(async error=>{
        console.log(error.error[0].description);
        if(error.error[0].description.includes("JWT has expired")){
          console.log("Renewing JWT token");
          await this.eclipseService.renewToken();
          setTimeout(async ()=>{
           this.get(wallId);
            },1000);
          }
      })
  }

}
