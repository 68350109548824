import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Transaction } from './transaction.model';

export interface TransactionState extends EntityState<Transaction,number> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'transaction',
  idKey:'transactionId'
})
export class TransactionStore extends EntityStore<TransactionState> {

  constructor() {
    super({ filter: 'ALL'});
  }

}
