import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  constructor(private sharedService: SharedService) { }

  public async takePhoto() {
    // Take a photo
    Camera.requestPermissions();

    const permision = (await Camera.checkPermissions()).camera;

    if (permision === 'denied') {
      this.sharedService.presentError('Error', 'Please allow this app to access your camera');
      return;
    }

    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera,
      quality: 50
    });
    return capturedPhoto.base64String;
  }
}
