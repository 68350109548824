import { ID } from '@datorama/akita';

export interface Customer {
  birthCity: string,
  birthCountry: string,
  created:string,
  customerId: ID,
  dateOfBirth: string,
  email: string,
  externalUniqueId: string,
  firstName: string,
  gender: string,
  lastModified: string,
  lastName: string,
  locale: string,
  maidenName: string,
  maritalStatus: string,
  middleName: string,
  nationalIdentityNumber: string,
  other: string,
  passportExpiry: string,
  passportNumber: string,
  passportPlaceOfIssuance: string,
  phone1: string,
  phone2: string,
  status: string,
  title: string,
  version: number
}

export function createCustomer(params: Partial<Customer>) {
  return {

  } as Customer;
}
